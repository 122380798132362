import React from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";

import Layout from "../../components/layout/Layout";
import PageHeader from "../../components/page-header/PageHeader";

import styles from "../pages.module.scss";
import BodyHeader from "../../components/body-header/BodyHeader";

export const query = graphql`{
  about: mdx(
    fileAbsolutePath: {regex: "src/data/copy/"}
    frontmatter: {title: {eq: "About OZARIN"}}
  ) {
    frontmatter {
      image {
        childImageSharp {
          gatsbyImageData(quality: 80, formats: [WEBP, JPG], layout: FULL_WIDTH)
        }
      }
      title
      category
      excerpt
      date(formatString: "MMM Do, YYYY")
      dateSimple: date(formatString: "YYYY-MM-DD")
      lastUpdate(formatString: "MMM Do, YYYY")
      lastUpdateSimple: date(formatString: "YYYY-MM-DD")
    }
    body
  }
}
`

const About = ( props ) => {

    const { body, frontmatter } = props.data.about;

    return (
        <Layout
            location={props.location}
            title={frontmatter.title}
            type={"page"}
        >
            <PageHeader
                image={frontmatter.image}
                alt={frontmatter.title}
            />

            <div className={styles.body}>
                <BodyHeader
                    frontmatter={frontmatter}
                />
                <MDXRenderer>{body}</MDXRenderer>
            </div>
        </Layout>
    )
}

export default About;